import './modules/material';
import './modules/smooth-scroll.min.js';

$(function(){

    // トップのマージン調整

    let $win = $(window);
    
    $win.on('load resize', function() {
        let nh = $('#nav_bar').outerHeight();
        $('body').css('margin-top', nh);
    });


    // ドロワーの開閉

    let isAnimate = false,
        $drowerBtn = $('#js-drawerBtn'),
        $mMenu = $("#mobile_menu li a");

    $drowerBtn.on('click', function() {
        
        if (isAnimate) {

            return;

        } else {
            
            isAnimate = true;

            if ($drowerBtn.attr('aria-expanded') == 'false') {
                $drowerBtn.attr('aria-expanded', true);
            } else {
                $drowerBtn.attr('aria-expanded', false);
            }

            $('#drawer').slideToggle(300, function(){
                
            }).promise().done(function(){
                isAnimate = false;
            });
        }

    });

    // メニューがクリックされたらドロワーを閉じる

    $mMenu.on('click', function() {
        if (isAnimate) {

            return;

        } else {
            
            isAnimate = true;

            if ($drowerBtn.attr('aria-expanded') == 'false') {
                $drowerBtn.attr('aria-expanded', true);
            } else {
                $drowerBtn.attr('aria-expanded', false);
            }

            $('#drawer').slideToggle(300, function(){
                
            }).promise().done(function(){
                isAnimate = false;
            });
        }
    });


    //スクロール設定np
    var scroll = new SmoothScroll('[data-scroll]',{
        speed: 600,
        speedAsDuration: true,
        easing: 'easeInOutQuart',
        header: '[data-scroll-header]',
        updateURL: false,
        topOnEmptyHash: true, 
    });

    //スクロールで表示
    ScrollReveal().reveal('.sr_contents_mv',{
        duration: 1400,
        easing: "ease-in-out",
        opacity: 0,
    });

    ScrollReveal().reveal('.sr_contents',{
        duration: 1000,
        distance: "50px",
        easing: "ease-in-out",
        viewFactor: 0.3,
        origin: "bottom",
        opacity: 0,
    });


});